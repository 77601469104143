import { Container } from "@mui/material";
import Source from "../components/Source";
import { useEffect, useState } from "react";
import SOURCES from "../api/sources";

const NewsPage = () => {
  const [sources, setSources] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("sources"));
    if (items) {
      setSources(items);
    }
  }, []);

  return (
    <Container maxWidth={false} disableGutters sx={{ mt: 10 }}>
      {SOURCES.filter(
        (i) => sources.length === 0 || sources.includes(i.code)
      ).map((s) => (
        <Source key={s.code} sourceConfig={s}/>
      ))}
    </Container>
  );
};

export default NewsPage;
