import {
  AppBar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";

const Header = () => {
  return (
    <AppBar component="nav">
      <Toolbar>
        <ListItemButton href={"/"}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Top Stories
          </Typography>
        </ListItemButton>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton href={"#/config"}>
              <ListItemText primary="Configuration" />
            </ListItemButton>
            <ListItemButton href={"/PrivacyPolicy.html"}>
              <ListItemText primary="About" />
            </ListItemButton>
          </ListItem>
        </List>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
