import moment from "moment";

const DEFAULT_PROXY = "https://corsproxy.io/?";
const CUSTOM_PROXY = "https://api.codetabs.com/v1/proxy?quest=";

const CUSTOM_PROXY_SOURCES = [
  "PRIMETIME",
  "NEWS_LINE",
  "FORMULA_NEWS",
  "EURONEWS_GEORGIA",
];

const fetch_html = (url, source) => {
  let proxyUrl;
  if (CUSTOM_PROXY_SOURCES.includes(source)) {
    proxyUrl = CUSTOM_PROXY + encodeURIComponent(url);
  } else {
    proxyUrl = DEFAULT_PROXY + encodeURIComponent(url);
  }

  return fetch(proxyUrl).then((res) => res.text());
};

const get_doc_from_html_text = (html) => {
  const parser = new DOMParser();
  return parser.parseFromString(html, "text/html");
};

const SOURCES = [
  {
    code: "IMEDI",
    name: "იმედი",
    url: "https://imedinews.ge/ge/archive",
    func: (url) => {
      return fetch_html(url).then((html) => {
        const news_list = [];

        const news = get_doc_from_html_text(html)
          .body.querySelector("div.news-list")
          .querySelectorAll("a.single-item");

        news.forEach((e, i) => {
          const temp = { id: i, url: e.getAttribute("href") };

          const img = e.querySelector("div.youtube-bg");
          if (img) {
            temp["img"] = img
              .getAttribute("style")
              .replace("background-image:url('", "")
              .replace("')", "");
          }

          const title = e.querySelector("h3.title");
          if (title) {
            temp["title"] = title.textContent;
          }

          const date = e.querySelector("p.date");
          if (date) {
            temp["date"] = date.textContent;
          }

          news_list.push(temp);
        });

        return { news: news_list };
      });
    },
  },
  {
    code: "MTAVARI",
    name: "მთავარი",
    url: "https://mtavari.tv/news/archive",
    func: (url) => {
      return fetch_html(url).then((html) => {
        const news =
          get_doc_from_html_text(html).body.querySelectorAll("a:has(section)");

        const news_list = [];

        news.forEach((e, i) => {
          const temp = {
            id: i,
            url: `https://mtavari.tv${e.getAttribute("href")}`,
          };

          const img = e.querySelector("img");
          if (img) {
            temp["img"] = img.getAttribute("src");
          }

          const title =
            e.querySelector("section").lastElementChild.lastElementChild;
          if (title != null) {
            temp["title"] = title.textContent;
          }

          const date = e.querySelector("time");
          if (date != null) {
            temp["date"] = date.textContent;
          }

          news_list.push(temp);
        });

        return { news: news_list };
      });
    },
  },
  {
    code: "FIRST_TV",
    name: "პირველი არხი",
    url: "https://1tv.ge/wp-json/witv/search/?search=&author=&searchFrom=&searchTo=&offset=0&posts_per_page=20&lang=ge&post_type=news&topics=0&filter_show=0&filter_channel=0&special_topics=0",
    func: (url) => {
      return fetch(`${url}`)
        .then((res) => res.json())
        .then(async (json) => {
          const res_data = json["data"];
          const posts = res_data["posts"];
          const news_list = [];

          posts.forEach((post) => {
            const obj = {
              id: post["ID"],
              url: post["post_permalink"],
              title: post["post_title"],
              date: post["post_date"],
            };
            news_list.push(
              fetch(post["post_permalink"])
                .then((res) => res.text())
                .then((html) => {
                  const img = get_doc_from_html_text(html).querySelector(
                    "body > div.body-wrapper > div > div.main.animation > div > div.content-wrapper > div.main-content.article-content.article-sport-content > div:nth-child(1) > div > img"
                  );
                  if (img) {
                    obj["img"] = img.getAttribute("src");
                  }
                  return obj;
                })
            );
          });

          return { news: await Promise.all(news_list) };
        });
    },
  },
  {
    code: "FORMULA_NEWS",
    name: "ფორმულა",
    url: "https://formulanews.ge/Category/all",
    func: (url) => {
      return fetch_html(url, "FORMULA_NEWS").then((html) => {
        const news = get_doc_from_html_text(html).body.querySelectorAll(
          "div.col-lg-3.news__box__card"
        );

        const news_list = [];

        news.forEach((e, i) => {
          const temp = { id: i };

          const url = e.querySelector("a");
          if (url) {
            temp["url"] = `https://formulanews.ge/${url.getAttribute("href")}`;
          }

          const img = e.querySelector("img");
          if (img) {
            temp["img"] = `https://formulanews.ge/${img.getAttribute("src")}`;
          }

          const title = e.querySelector(
            "#news__box > div > div > div.main__new__slider__desc > a"
          );
          if (title) {
            temp["title"] = title.textContent;

            const date = e.querySelector(
              "#news__box > div > div > div.main__new__slider__desc > div"
            );
            if (date) {
              temp["date"] = date.textContent;
            }

            news_list.push(temp);
          }
        });

        return { news: news_list };
      });
    },
  },
  {
    code: "EURONEWS_GEORGIA",
    name: "Euronews Georgia",
    url: "https://euronewsgeorgia.com/",
    func: (url) => {
      return fetch_html(url, "EURONEWS_GEORGIA").then((html) => {
        const news =
          get_doc_from_html_text(html).body.querySelectorAll("#tdi_140 > div");

        const news_list = [];

        news.forEach((e, i) => {
          const temp = { id: i };

          const url = e.querySelector(
            "#tdi_140 > div > div > div.td-image-container > div.td-module-thumb > a"
          );
          if (url) {
            temp["url"] = url.getAttribute("href");
          }

          const img = e.querySelector(
            "#tdi_140 > div > div > div.td-image-container > div.td-module-thumb > a > span.entry-thumb.td-thumb-css"
          );
          if (img) {
            temp["img"] = img
              .getAttribute("style")
              .replace("background-image: url('", "")
              .replace("')", "");
          }

          temp["title"] = url.getAttribute("title");

          const date = e.querySelector(
            "div > div.td-image-container > div.td-post-vid-time"
          );
          if (date) {
            temp["date"] = date.textContent;
          }

          news_list.push(temp);
        });

        return { news: news_list };
      });
    },
  },
  {
    code: "PRIMETIME",
    name: "პრაიმთაიმი",
    url: "https://primetime.ge/news/all",
    func: (url) => {
      return fetch_html(url, "PRIMETIME").then((html) => {
        const news =
          get_doc_from_html_text(html).body.querySelectorAll("div.row.news");

        const news_list = [];

        news.forEach((e, i) => {
          const temp = { id: i };

          const url = e.querySelector("a");
          if (url) {
            temp["url"] = url.getAttribute("href");
          }

          const img = e.querySelector("img.photo");
          if (img) {
            temp["img"] = img.getAttribute("src");
          }

          const title = e.querySelector("a.title");
          if (title) {
            temp["title"] = title.text;
          }

          const date = e.querySelector("p.date");
          if (date) {
            temp["date"] = moment.utc(moment.unix(date.textContent)).fromNow();
          }

          news_list.push(temp);
        });

        return { news: news_list };
      });
    },
  },
  {
    code: "NEWS_LINE",
    name: "სიახლეების ხაზი",
    url: "https://newsline.ge/axali-ambebi",
    func: (url) => {
      return fetch_html(url, "NEWS_LINE").then((html) => {
        const news =
          get_doc_from_html_text(html).body.querySelectorAll("article.n-new-1");

        const news_list = [];

        news.forEach((e, i) => {
          const temp = { id: i };

          const url = e.querySelector("a");
          if (url) {
            temp["url"] = url.getAttribute("href");
          }

          const img = e.querySelector("img");
          if (img) {
            temp["img"] = `https://newsline.ge${img.getAttribute("src")}`;
          }

          const title = e.querySelector("div.n-new-7");
          if (title) {
            temp["title"] = title.textContent;
          }

          const date = e.querySelector("div.n-new-9");
          if (date) {
            temp["date"] = date.textContent;
          }

          news_list.push(temp);
        });

        return { news: news_list };
      });
    },
  },
];

export default SOURCES;
