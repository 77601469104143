import { useEffect, useState } from "react";
import {
  Container,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
} from "@mui/material";
import SOURCES from "../api/sources";

const ConfigPage = () => {
  const [sources, setSources] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("sources"));
    if (items) {
      setSources(items);
    }
  }, []);

  const handleChange = (event) => {
    let temp = [];
    if (event.target.checked) {
      temp = [...sources, event.target.name];
    } else {
      temp = sources.filter((e) => e !== event.target.name);
    }
    localStorage.setItem("sources", JSON.stringify(temp));
    setSources(temp);
  };

  return (
    <Container sx={{ mt: 10 }}>
      <FormGroup>
        {SOURCES.map((item) => (
          <FormControlLabel
            key={item.code}
            control={
              <Switch
                checked={sources.includes(item.code)}
                onChange={handleChange}
                name={item.code}
              />
            }
            label={item.name}
          />
        ))}
        <FormHelperText>
          მონიშნული წყაროები ინახება თქვენს ბრაუზერში
        </FormHelperText>
      </FormGroup>
    </Container>
  );
};

export default ConfigPage;
