import NewsPage from "./pages/NewsPage";
import {CssBaseline} from "@mui/material";
import Header from "./components/Header";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {
    HashRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import ConfigPage from "./pages/ConfigPage";

const queryClient = new QueryClient()

const App = () => (
    <QueryClientProvider client={queryClient}>
        <Router>
            <CssBaseline/>
            <Header/>
            <Routes>
                <Route path="/config" element={<ConfigPage/>}/>
                <Route path="/" element={<NewsPage/>}/>
            </Routes>
        </Router>
    </QueryClientProvider>
);

export default App;
