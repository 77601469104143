import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

const News = ({ title, img, url, date, height }) => {
  return (
    <CardActionArea target={"_blank"} href={url}>
      <Card sx={{ width: 345, height: height }}>
        {img ? (
          <CardMedia height="140" component="img" image={img} alt={{ title }} />
        ) : (
          <></>
        )}
        <CardContent>
          <Typography gutterBottom variant="h7" component="div">
            <b>{date}</b> - {title}
          </Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

export default News;
