import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import News from "./News";
import { useQuery } from "@tanstack/react-query";

const Source = ({ sourceConfig }) => {
  const { code, name, url, func } = sourceConfig;
  const { isLoading, error, data } = useQuery(["news-" + code], () =>
    load_source_data()
  );

  const load_source_data = async () => {
    if (func) {
      return func(url);
    }
    return { news: [] };
  };

  if (isLoading) return <p>{name} იტვირთება...</p>;

  if (error) return "An error has occurred: " + error.message;

  return (
    <Box sx={{ flexGrow: 1 }} mb={2}>
      <Typography gutterBottom variant="h5" component="div" ml={2}>
        {name}
      </Typography>
      <Paper style={{ overflow: "auto" }} elevation={0}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {data.news.map((news) => (
            <Grid key={news.id} padding={1}>
              <News
                title={news.title}
                img={news.img}
                url={news.url}
                date={news.date}
                height={news.img ? 400 : 200}
              />
            </Grid>
          ))}
        </Stack>
      </Paper>
    </Box>
  );
};

export default Source;
